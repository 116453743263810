.content {
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 1.25rem;
  display: flex;
}

.logo {
  width: 6.25em;
  max-width: 100%;
  height: auto;
  fill: currentColor;
  margin: 0 0 3.75em;
  display: block;
}

.title {
  text-align: center;
  margin: 0;
  font-size: 2.25em;
  font-weight: bold;
}

.subtitle {
  text-align: center;
  margin: 0 0 3em;
  font-size: 1.25em;
}

.subtitle span {
  opacity: .75;
}

/*# sourceMappingURL=index.93800dc9.css.map */
